<template>
    <b-card class="pb-1">
      <div class="float-right">
        <b-button variant="link" @click="openPrintDailyEventsModal"><i class="icon-printer"></i></b-button>
      </div>
      <b-card-title>Ραντεβού ημέρας</b-card-title>
      <b-card-sub-title>Δείτε τα επόμενα ραντεβού της ημέρας σας</b-card-sub-title>

      <b-card-text class="mt-2" style="min-height:340px; max-height:340px;overflow-y:scroll;overflow-x:hidden">
        <div v-if="events.length > 0 " class="p-2" >
          <Event v-for="event in events" :key="event.id" :event="event" :is-home="true"/>
        </div>
        <div v-else class="text-center">
          <div class="pt-3 pb-3"><img src="@assets/images/no-events.svg" class="img-elements-not-found" alt="" height="150"/></div>
          <p>Δεν έχετε άλλα ραντεβού σήμερα!</p>
        </div>
      </b-card-text>

      <b-modal ref="print-daily-events" hide-footer hide-header>

        <b-overlay :show="isPrinting" rounded="sm">
          <div class="row">
            <div class="col-12">
              <b-form-group id="input-birthDate" label="Επιλέξτε ημερομηνία*" label-for="birthDate">
                <date-picker v-model="selectedDate"  :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY" placeholder="Ημέρα/μήνας/έτος"></date-picker>
              </b-form-group>

              <b-form-checkbox id="showCancelled" v-model="showCancelled" name="showCancelled">Εμφάνιση ακυρωμένων ραντεβού;</b-form-checkbox>


            </div>
            <div class="col-12 mt-3 text-right">
              <b-button variant="success" @click="initPrinting">Εκτύπωση</b-button>
            </div>
          </div>
        </b-overlay>

        <div v-if="isPrinting" id="print-area" ref="print-area">
          <print-header v-show="isPrinting" :title="title"/>

          <div class="note-body print-events">

            <b-table striped bordered hover :items="printEvents" :fields="fields" style="text-align:left!important;">
              <!-- A custom formatted column -->
              <template #cell(date)="data" class="print-event-date">  {{data.item.startTime}} - {{data.item.endTime}}</template>
              <template #cell(title)="data">
                <span v-if="data.item.isCancelled" style="background-color:#dc3545;color:#fff;padding:3px;">ΑΚΥΡΩΜΕΝΟ</span> {{data.item.title}}
                <p v-if="data.item.notes" class="font-weight-normal mb-0">
                  {{data.item.notes}}
                </p>
              </template>
            </b-table>
          </div>

        </div>


      </b-modal>
    </b-card>
</template>
<script>
import moment from "moment";
import Event from "@components/Event";
import DatePicker from 'vue2-datepicker'
import PrintMixin from "@src/mixins/PrintMixin";
import printHeader from '@components/print/header';

export default{

  components:{Event, DatePicker, printHeader},
  mixins: [PrintMixin],

  mounted(){
    this.fetchEvents();
    // update events every 30sec
    this.timeout = setInterval(()=>{
      this.updateEvents();
    }, 30000);
  },
  destroyed(){
    clearInterval(this.timeout);
  },
  computed: {
    getSelectedDate(){
      return moment([this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate()]).format('YYYY-MM-DD');
    },
    title(){
      return 'Ραντεβού ημέρας: ' + this.formatDateWithoutHour(this.getSelectedDate);
    }
  },
  data(){
    return{
      events: [],
      timeout: null,
      selectedDate: new Date(),
      showCancelled: false,
      isPrinting: false,
      printEvents: [],
      fields: [
        // A column that needs custom formatting
        { key: 'date', label: 'Ώρα' },
        // A virtual column made up from two fields
        { key: 'title', label: 'Πελάτης - Σημείωση' }
      ],
    }
  },
  methods:{
    fetchEvents: async function(){
      this.$axios.get(`/calendar`, {params: {date: moment().format('YYYY-MM-DD')}}).then(res=>{
        for(let event of res.data) {
          event.start = moment.utc(event.date).set('hour', event.startTime.substring(0, 2)).set('minute', event.startTime.substring(3, 5)).toISOString();
          event.end = moment.utc(event.date).set('hour', event.endTime.substring(0, 2)).set('minute', event.endTime.substring(3, 5)).toISOString();
          this.events.push(event);
        }
        this.events = this.events.filter(x => !x.isCancelled); //hide cancelled events

        this.updateEvents();

      }).catch(e=>{});
    },

    initPrinting(){
      this.isPrinting = true;
      this.printEvents = [];
      this.$axios.get(`/calendar`, {params: {date: this.getSelectedDate}}).then(res => {
        for(let event of res.data) {
          event.start = moment.utc(event.date).set('hour', event.startTime.substring(0, 2)).set('minute', event.startTime.substring(3, 5)).toISOString();
          event.end = moment.utc(event.date).set('hour', event.endTime.substring(0, 2)).set('minute', event.endTime.substring(3, 5)).toISOString();
          this.printEvents.push(event);
        }

        if(!this.showCancelled) this.printEvents = this.printEvents.filter(x => !x.isCancelled);

        this.printEvents.sort((a, b) => {
          return new Date(a.start) - new Date(b.start)
        });

        setTimeout(()=>{
          let divToPrint = this.$refs['print-area'].outerHTML;
          this.isPrinting = false;
          this.print(divToPrint);
        }, 500);



      }).catch(e=>{
        this.isPrinting = false;
      })

    },

    openPrintDailyEventsModal(){
      this.$refs['print-daily-events'].show()
    },


    updateEvents(){

      this.events = this.events.filter(x => {
        const date = new Date();
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let endHours = parseInt(x.endTime.substring(0,2));
        let endMinutes = parseInt(x.endTime.substring(3,5));

        if(endHours < hours) return false;
        if(endHours === hours && endMinutes < minutes) return false;

        return true;
      });

      this.events.sort((a, b) => {
        return new Date(a.start) - new Date(b.start)
      });
    },
  }
}
</script>
