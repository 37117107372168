<template>

  <div class="event-item">

    <div class="row">
      <div class="col-1">
        <div class="event-grid-line" :class="getClass"/>
      </div>

      <div class="col-11 event-item-details " :class="getClass">
        <div v-if="!isHome" class="date">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
              <g id="Calendar">
                <path fill="#3155A6FF" d="M60.0002518,6.0037999h-15V1c0-0.5527-0.4472008-1-1-1c-0.5527,0-1,0.4473-1,1v5.0037999H22.0002499V1
                  c0-0.5527-0.4472008-1-1-1c-0.5527,0-1,0.4473-1,1v5.0037999H3.9997499c-2.2090998,0-4,1.7908001-4,4.0000005V60
                  c0,2.2090988,1.7909,4,4,4h56.0005035c2.209198,0,4-1.7909012,4-4V10.0038004
                  C64.0002518,7.7946,62.2094498,6.0037999,60.0002518,6.0037999z M3.9997499,8.0038004h16.0004997V11c0,0.5527,0.4473,1,1,1
                  c0.5527992,0,1-0.4473,1-1V8.0038004h21.0000019V11c0,0.5527,0.4473,1,1,1c0.5527992,0,1-0.4473,1-1V8.0038004h15
                  c1.1027985,0,2,0.8970995,2,2V17h-60L1.99975,17.0000992v-6.9962988C1.99975,8.9008999,2.8970499,8.0038004,3.9997499,8.0038004z
                   M62.0002518,60c0,1.1027985-0.8972015,2-2,2H3.9997499c-1.1027,0-1.9999999-0.8972015-1.9999999-2V18.9999008L2.0002501,19h60V60z
                  "/>
                <path fill="#3155A6FF" d="M18.0002499,24h-8v8h8V24z M16.0002499,30h-4v-4h4V30z"/>
                <path fill="#3155A6FF" d="M36.0002518,24h-8.0000019v8h8.0000019V24z M34.0002518,30h-4.0000019v-4h4.0000019V30z"/>
                <path fill="#3155A6FF" d="M54.0002518,24h-8v8h8V24z M52.0002518,30h-4v-4h4V30z"/>
                <path fill="#3155A6FF" d="M18.0002499,36h-8v8h8V36z M16.0002499,42h-4v-4h4V42z"/>
                <path fill="#3155A6FF" d="M36.0002518,36h-8.0000019v8h8.0000019V36z M34.0002518,42h-4.0000019v-4h4.0000019V42z"/>
                <path fill="#3155A6FF" d="M18.0002499,48h-8v8h8V48z M16.0002499,54h-4v-4h4V54z"/>
                <path fill="#3155A6FF" d="M36.0002518,48h-8.0000019v8h8.0000019V48z M34.0002518,54h-4.0000019v-4h4.0000019V54z"/>
                <path fill="#3155A6FF" d="M54.0002518,36h-8v8h8V36z M52.0002518,42h-4v-4h4V42z"/>
                <path fill="#3155A6FF" d="M54.0002518,48h-8v8h8V48z M52.0002518,54h-4v-4h4V54z"/>
              </g>
          </svg>

          {{getDate(event.date)}}
        </div>
        <div class="hour">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 488 488" style="enable-background:new 0 0 488 488;" xml:space="preserve">
            <g transform="translate(0 -540.36)">
              <g>
                <g>
                  <path fill="#3155A6FF" d="M351.1,846.96l-97.1-67.9v-116.7c0-5.5-4.5-10-10-10s-10,4.5-10,10v122c0,3.3,1.6,6.3,4.3,8.2l101.4,70.9
                    c1.7,1.2,3.7,1.8,5.7,1.8v0c3.1,0,6.2-1.5,8.2-4.4C356.7,856.36,355.6,850.16,351.1,846.96z"/>
                  <path fill="#3155A6FF" d="M416.4,611.96L416.4,611.96c-46.2-46.2-107.4-71.6-172.4-71.6s-126.2,25.4-172.4,71.6C25.4,658.16,0,719.36,0,784.36
                    s25.4,126.2,71.6,172.4c46.2,46.2,107.4,71.6,172.4,71.6s126.2-25.4,172.4-71.6s71.6-107.4,71.6-172.4S462.6,658.16,416.4,611.96
                    z M254,1008.16L254,1008.16v-40.8c0-5.5-4.5-10-10-10s-10,4.5-10,10v40.8c-115.6-5.1-208.7-98.2-213.8-213.8H61
                    c5.5,0,10-4.5,10-10s-4.5-10-10-10H20.2c5.1-115.6,98.2-208.7,213.8-213.8v40.8c0,5.5,4.5,10,10,10s10-4.5,10-10v-40.8
                    c115.6,5.1,208.7,98.2,213.8,213.8H427c-5.5,0-10,4.5-10,10s4.5,10,10,10h40.8C462.7,909.96,369.6,1003.06,254,1008.16z"/>
                </g>
              </g>
            </g>
        </svg>
          {{event.startTime}} - {{event.endTime}}
        </div>

        <h4 v-if="isHome" @click="redirectToAthlete">{{event.title}}</h4>
        <p v-if="event.notes" class="font-weight-normal mb-0">
          {{event.notes}}
        </p>
      </div>
    </div>


  </div>

</template>

<style lang="scss">
.event-item{
  margin-bottom:1em;
}
.event-item-details{
  background-color:#e3ebfe;
  border-radius:10px;
  padding:1em 1em;
  font-size:1em;
  color:$color_primary;
  font-weight:bold;

  &.notes{
    background-color: $event_notes_bg;
  }
  &.cancelled{
    background-color: $event_cancelled_bg;
  }
  &.is-past{
    background-color:$event_success_bg;
  }

  svg{
    width:15px;
    margin-right:.5em;
    &:nth-child(2){
      margin-left:.5em;
    }
  }
  h4{
    font-weight:500;
    font-size:1.2em;
    color:$color_primary;
    cursor:pointer;
  }

}
.event-grid-line{
  min-height: 100%;
  width:100%;
  margin:0 auto;
  border-radius:12px;
  background-color:$color_primary;
  &.notes{
    background-color: $event_notes_primary;
  }
  &.cancelled{
    background-color: $event_cancelled_primary;
  }
  &.is-past{
    background-color:$event_success_primary;
  }
  max-width:10px;
}
</style>

<script>
import moment from "moment"
export default{
  props:{
    event: Object,
    isHome:{
      type: Boolean,
      default: false
    },
    isPast:{
      type: Boolean,
      default: false
    }
  },

  computed:{
    getClass(){
      if(this.event.isCancelled) return 'cancelled';
      if(this.isPast) return 'is-past';
      if(this.event.type === 0) return 'notes';
      return '';
    }
  },

  methods:{
    redirectToAthlete(){
      if(this.event.athleteId && this.isHome) this.$router.push({name: 'view-athlete', params: {athleteId: this.event.athleteId}})
    }
  }
}
</script>
