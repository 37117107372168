<script>
import countTo from 'vue-count-to'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import axios from 'axios'
import NewCustomerModal from "@components/global/NewCustomerModal";

import goal from "@components/todo";
import {mapState} from "vuex";
import moment from "moment";
import CalendarEventModal from "@components/global/CalendarEventModal";
import HomeEvents from "@components/HomeEvents";
/* eslint-disable */
export default {
  page: {
    title: 'Πίνακας ελέγχου',
    meta: [{ name: 'description' }],
  },
  components: { countTo, Todo: goal, VuePerfectScrollbar, NewCustomerModal, CalendarEventModal, HomeEvents},
  data() {
    return {
      userCounts: {
        uncompletedToDos: {
          count: 0,
          message: 'TO-DO',
          icon: require('@assets/icons/todo-icon.svg'),
        },
        athletes: {
          count: 0,
          message: 'ΠΕΛΑΤΕΣ',
          icon: require('@assets/icons/client-count-icon.svg'),
        },
        dietPlans: {
          count: 0,
          message: 'ΔΙΑΤΡΟΦΙΚΑ ΠΛΑΝΑ',
          icon: require('@assets/icons/dp-count-icon.svg'),
        }
      },
      title: 'Πίνακας ελέγχου',
      state: {
        date: new Date(),
      },
      user: this.$store ? this.$store.state.auth.currentUser : {} || {},
      toDoList: this.user ? this.user.userToDo : [],
      showFirstTimeModal: false,
      tableInfo: {
        fields: [
          {key: 'title', label: 'Τίτλος'},
          {key: 'smallParagraph', label: 'Σύντομο κείμενο'},
          {key: 'content', label: 'Περιεχόμενο'},
        ],
        perPage: 3,
        totalRows: null,
        currentPage: 1,
        offset: 0,
      },
      birthdayAthletes: [],
      blogArticles: [],
      athletes: [],
      modalEvent: null
    }
  },
  computed:{
    ...mapState({
      news: state => state.news.announcements
    })
  },
  created(){
    this.$store.subscribe((mutation, state) => {
      if(mutation.type === 'auth/SET_CURRENT_USER'){
        this.user = this.$store.state.auth.currentUser;
      }
    });
    this.fetchBlog();
    this.getUserCounts();
    this.fetchAthletes();
  },

  methods: {
    fetchAthletes: async function(){
      if(this.athletes.length < 1){
        this.$axios.get(`/athlete/athlete-names`).then(result => {
          this.athletes = result.data.athletes.rows;
          this.athletes.map(x =>{
            x.item_data = x.firstName + ' ' + x.lastName;
            return x;
          });
        }).catch(e =>{
          this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η φόρτωση των αθλητών απέτυχε. Δοκιμάστε ξανά!'});
        });
      }
    },
    addEventModal: async function(){
      this.modalEvent = Object.assign({},this.newEvent);
      this.modalEvent.date = moment();
      this.modalEvent.startTime = moment().format('HH:mm');
      this.modalEvent.endTime = moment().add(this.modalEvent.duration, 'minutes').format('HH:mm');
      this.$refs['add-event'].show();
    },
    clientCreated(){
      this.$refs['create-client'].hide();
    },
    eventCreated(){
      this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το συμβάν / ραντεβού προστέθηκε επιτυχώς!'});
      this.modalEvent = null;
      this.$refs['add-event'].hide();
      this.modalEvent = Object.assign({}, this.newEvent); // clear modalEvent object
    },

    getUserCounts(){
      this.$axios.get(`/users/dashboard/`).then(result => {
        this.userCounts.uncompletedToDos.count = result.data.uncompletedToDos;
        this.userCounts.athletes.count = result.data.athleteCount;
        this.userCounts.dietPlans.count = result.data.dietPlanCount;
        result.data.birthdayCustomers = result.data.birthdayCustomers.filter(x => { return parseInt(x.birthDate.substring(8)) >= new Date().getDate()});
        this.birthdayAthletes = result.data.birthdayCustomers.sort((a,b) => {return a.birthDate.substring(8) - b.birthDate.substring(8)});

      }).catch(e => {
        console.error(e);
      });
    },
    reSendEmailVerification(){
      this.$axios.put(`/users/resend-verification`).then(result=>{
        console.log(result);
      })
    },
    fetchBlog(){
      let params = {
        _limit: 2,
        _sort: 'id:DESC'
      };
      let instance = axios.create();

      instance.get('https://cms.athlisis.com/articles',{params: params, transformRequest: (data, headers) => {
          delete headers.common['x-dietitian-token'];
          delete headers.common['x-access-token'];
        }}).then(res => {
        this.blogArticles = this.fixLinks(res.data);
      });
    },
    fixLinks(articles){

      for(let article of articles){
        let originalUrl = 'https://athlisis-new.s3.eu-north-1.amazonaws.com';
        article.image =  article.featuredImage.formats.thumbnail.url.replace(new RegExp(originalUrl, 'g'), 'https://media.athlisis.com');
      }

      return articles;

    },
    redirectToArticle(slug){
      window.open("https://www.athlisis.com/blog/"+slug, "_blank");
    },
    toggleCollapse(id){
      this.$root.$emit('bv::toggle::collapse', id)
    },

  }
}
</script>

<template>
  <div id="home-layout-wrap">

    <b-alert :show=" user && !user.isActivated && !isEducational" variant="warning">Δεν έχετε επιβεβαιώσει το e-mail σας.
      <b-button variant="link" class="p-0" @click="reSendEmailVerification"><strong>Επαναποστολή συνδέσμου επιβεβαίωσης</strong></b-button>
    </b-alert>

    <div class="row mt-4">
      <div class="col-12 col-xl-8">

        <div class="row ">
          <div class="col-12">
            <div class="card welcome-card ">
              <h1>Kαλώς όρισες στο athlisis <span class="text-red" v-if="isEducational"> Educational 🎓</span></h1>
              <p class="color-muted">
                Tη No1 εφαρμογή για διατροφολόγους στην Ελλάδα!
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div v-for="count in userCounts" :key="count.message" class="col-12 col-xl-4">
            <div class="card p-4">
              <div class="row align-items-center">
                <div class="col-5 col-md-4">
                  <img :src="count.icon"/>
                </div>
                <div class="col-7">
                  <h2 class="count-number text-primary mt-1"><span>
                  <countTo :end-val="count.count" :duration="2500"></countTo></span>
                  </h2>
                  <p class="text-primary-muted mb-1 text-truncate">{{count.message}}</p>
                </div>
              </div>
              <!-- end row-->
            </div>
            <!-- end widget-rounded-circle-->
          </div>
        </div>

      </div>

      <div class="col-12 col-xl-4">
        <div class="row mb-3">
          <div class="col-12 col-md-6">
            <b-button variant="outline-primary" class="btn-block cta-btn" @click="$refs['create-client'].show()">
              <svg id="clients-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.817" height="14.241" viewBox="0 0 12.817 14.241" class="mr-1">
                <defs>
                  <clipPath id="clip-path">
                    <path id="icon_user" data-name="icon / user" d="M12.1,14.241a.674.674,0,0,1-.712-.712V12.1A2.1,2.1,0,0,0,9.256,9.969H3.56A2.1,2.1,0,0,0,1.424,12.1v1.424a.674.674,0,0,1-.712.712A.674.674,0,0,1,0,13.529V12.1a3.523,3.523,0,0,1,3.56-3.56h5.7a3.523,3.523,0,0,1,3.56,3.56v1.424A.674.674,0,0,1,12.1,14.241Zm-5.7-7.12a3.522,3.522,0,0,1-3.56-3.56A3.523,3.523,0,0,1,6.408,0a3.523,3.523,0,0,1,3.56,3.56A3.522,3.522,0,0,1,6.408,7.12Zm0-5.7a2.1,2.1,0,1,0,1.522.614A2.131,2.131,0,0,0,6.408,1.424Z" fill="#475f93"/>
                  </clipPath>
                </defs>
                <path id="icon_user-2" data-name="icon / user" d="M12.1,14.241a.674.674,0,0,1-.712-.712V12.1A2.1,2.1,0,0,0,9.256,9.969H3.56A2.1,2.1,0,0,0,1.424,12.1v1.424a.674.674,0,0,1-.712.712A.674.674,0,0,1,0,13.529V12.1a3.523,3.523,0,0,1,3.56-3.56h5.7a3.523,3.523,0,0,1,3.56,3.56v1.424A.674.674,0,0,1,12.1,14.241Zm-5.7-7.12a3.522,3.522,0,0,1-3.56-3.56A3.523,3.523,0,0,1,6.408,0a3.523,3.523,0,0,1,3.56,3.56A3.522,3.522,0,0,1,6.408,7.12Zm0-5.7a2.1,2.1,0,1,0,1.522.614A2.131,2.131,0,0,0,6.408,1.424Z" transform="translate(0 0)" fill="#475f93"/>
              </svg>

              Προσθήκη πελάτη
            </b-button>
          </div>
          <div class="col-12 col-md-6 mt-2 mt-md-0">
            <b-button variant="outline-primary" class="cta-btn btn-block" @click="addEventModal">
              <svg xmlns="http://www.w3.org/2000/svg" width="17.114" height="18.468" viewBox="0 0 17.114 18.468" class="mr-1">
                <g id="calendar-icon" transform="translate(0.25 0.25)">
                  <g id="Group_90" data-name="Group 90" transform="translate(0)">
                    <path id="Path_36" data-name="Path 36" d="M12.846,26.888H3.768A3.772,3.772,0,0,1,0,23.12V14.042a3.772,3.772,0,0,1,3.768-3.768h9.078a3.772,3.772,0,0,1,3.768,3.768V23.12a3.772,3.772,0,0,1-3.768,3.768M3.768,11.111a2.934,2.934,0,0,0-2.93,2.93V23.12a2.934,2.934,0,0,0,2.93,2.93h9.078a2.934,2.934,0,0,0,2.93-2.93V14.042a2.934,2.934,0,0,0-2.93-2.93Z" transform="translate(0 -8.92)" fill="#475f93" stroke="#475f93" stroke-width="0.5"/>
                    <path id="Path_37" data-name="Path 37" d="M17.2,47.922H1.781a.419.419,0,0,1,0-.837H17.2a.419.419,0,0,1,0,.837" transform="translate(-1.182 -40.879)" fill="#475f93" stroke="#475f93" stroke-width="0.5"/>
                    <path id="Path_38" data-name="Path 38" d="M30.433,4.044a.419.419,0,0,1-.419-.419V.419a.419.419,0,0,1,.837,0V3.626a.419.419,0,0,1-.419.419" transform="translate(-26.058)" fill="#475f93" stroke="#475f93" stroke-width="0.5"/>
                    <path id="Path_39" data-name="Path 39" d="M90.1,4.044a.419.419,0,0,1-.419-.419V.419a.419.419,0,1,1,.837,0V3.626a.419.419,0,0,1-.419.419" transform="translate(-77.864)" fill="#475f93" stroke="#475f93" stroke-width="0.5"/>
                  </g>
                </g>
              </svg>

              Προσθήκη ραντεβού
            </b-button>
          </div>
        </div>

        <home-events v-if="!isEducational"/>
        <div v-else>
          <div class="card educational-card text-center">
            <img src="@assets/images/educational-discount.svg" class="img-elements-not-found mb-2" alt="" height="175"/>
            <p>
              Ήξερες ότι λόγω της σχολής σου μπορείς να αποκτήσεις το athlisis με την μοναδική έκπτωση 48% (και δυνατότητα 2 δόσεων) από
              <strong><del>2100€</del>
              </strong> μόνο <strong>1100€</strong>, χρησιμοποιώντας τον εκπτωτικό κωδικό
            </p>
            <h2 class="text-red mb-4" style="font-weight:900">EDUCATION1100</h2>
            <a href="https://www.athlisis.com" class="font-weight-bold" target="_blank">Επισκέψου το athlisis.com τώρα!</a>
          </div>
        </div>
      </div>

      <!-- end col-->
    </div>
    <div class="row">
      <div class="col-xl-4">
        <b-card class="pb-1">
          <b-card-title>Τελευταία νέα</b-card-title>
          <b-card-sub-title>Διαβάστε τα τελευταία μας νεά στο blog του athlisis</b-card-sub-title>
          <b-card-text class="mt-4 row h-100">
            <div v-for="article in blogArticles" class="col-12 col-xl-6 mb-2 h-100 ">
              <div class="h-100 article-link" @click="redirectToArticle(article.slug_el)">

                  <img :src="article.image" class="img-fluid rounded-image"/>
                <div class="article-content">
                  <h4>{{article.title_el}}</h4>
                  <b-button variant="primary" class="mt-2" size="sm" @click="redirectToArticle(article.slug_el)">Δείτε το άρθρο</b-button>

                </div>
                </div>
            </div>
          </b-card-text>
        </b-card>
      </div>

      <div class="col-xl-4">
        <div class="card-title birthday-card-title mb-0 d-flex  align-items-center">
          <div class="row align-items-center justify-content-center h-100 text-center flex-grow-1">
            <div class="col-12">
              <h4 class="text-primary">Πελάτες που έχουν γενέθλια στον μήνα</h4>
              <p class="text-primary-muted">Μην ξεχάσετε να τους ευχηθείτε!</p>
            </div>
          </div>
        </div>

        <b-card class="mt-0 pt-0 birthday-card-body">

          <b-card-text class="pt-2">
            <p v-if="birthdayAthletes.length === 0" class="text-center">
              Δεν έχει κανένας πελάτης σας γενέθλια αυτό το μήνα.
            </p>
            <VuePerfectScrollbar v-else>
              <div  class="p-1" style="max-height: 325px; overflow-x:hidden;">
              <div v-for="athlete of birthdayAthletes" :key="athlete.id" class="card shadow-sm p-0 announcement-item"
                   @click="$router.push('/athletes/' + athlete.id)">

                <div class="row align-items-center card-body">
                  <div class="col-2 align-self-center text-center pl-3 pr-3">
                    <img class="rounded-circle" style="max-width:40px;" :src="athlete.image || 'https://www.gravatar.com/avatar/1db7993cb4a4a74ce58f4a6926cec867?s=80&d=mp&r=g'"/>

                  </div>
                  <div class="col-6 pl-3">
                    <div>
                      <h4 class="text-primary mt-1 mb-0 font-15 pr-1"><strong>{{athlete.firstName + ' ' + athlete.lastName}}</strong></h4>
                      <span class="text-primary-muted">{{formatDateWithoutHour(athlete.birthDate).substring(0,5)}}</span><br>
                      <span v-if="athlete.mobileNum" class="text-muted text-sm-right">Τηλ: {{athlete.mobileNum}}</span>
                    </div>
                  </div>
                  <div class="col-4 text-right align-self-center">
                    <b-button variant="primary" size="xs">προβολή καρτέλας</b-button>
                  </div>
                </div>
              </div>
              </div>
            </VuePerfectScrollbar>


          </b-card-text>
        </b-card>

      </div>

      <div class="col-xl-4">


        <Todo :todo-data="user ? user.userToDo : []"></Todo>
      </div>

    </div>
    <b-modal ref="create-client" id="create-client" title="Γρήγορη δημιουργία πελάτη" size="md" hide-footer>
      <NewCustomerModal @clientCreated="clientCreated"/>
    </b-modal>
    <b-modal id="add-event" ref="add-event" :click-to-close="false" title="Προσθήκη νέου ραντεβού" hide-footer body-class="p-3" >
      <calendar-event-modal v-if="modalEvent" :modal-event="modalEvent" :athletes="athletes"
                            @eventUpdated="eventCreated"/>
    </b-modal>
  </div>
</template>
<style lang="scss">

.birthday-card-title{
  background-image:url('~~~@assets/icons/birthday-header.svg');
  background-size:cover;
  background-repeat:no-repeat;
  background-position: bottom center;
  min-height:15em;
  background-color:#fff;
  @media(max-width:767px){
    min-height:10em;
    padding:2em;
  }

}
.birthday-card-body{
  border-top-right-radius:0!important;
  border-top-left-radius:0!important;
}
.cta-btn{
  &:hover{
    svg{
      path{
        fill:#fff;
        stroke:#fff;
      }

    }
  }

}
.count-number{
  font-size:2.5em;
  font-weight:800;
}
  .announcement-item{
    cursor:pointer;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    .announcement-title{
      padding:0;
      margin:0;
      font-size:1.15em;
      color:$color_primary;
      font-weight:700;
    }
    &:hover{
      background-color: #f5f9ff;
    }

    .card-body{
      padding:0.8rem;
    }
  }
  .article-link{
    background-color:$color_light_bg;
    cursor:pointer;
    text-align:center;
    img{
      max-height:10em;
    }
    .article-content{
      h4{
        font-size: 1.1em;
        color:$color_primary;
      }
      padding:1em;

    }
  }
  .welcome-card{
    padding:8em 3em;
      background-image: url('~~~@assets/icons/welcome-bg.svg');
    background-size:cover;
    background-repeat:no-repeat;
    background-position: center center;

    h1{
      color:$color_primary;
      font-weight:700;
    }
    p{
      color:$color_primary;
      opacity:0.7;
      letter-spacing: 1.1px;
    }
  }

  .educational-card{
    padding:1em 3em;
    background-color: #e3ebfe;

    h1{
      color:$color_primary;
      font-weight:700;
    }
    p{
      color:$color_primary;
      opacity:0.7;
      letter-spacing: 1.1px;
      a{
        color:$color_primary;
      }
    }
  }
</style>
